import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../../user';
import {log} from 'util';
import {RequestOptions} from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public url = 'https://matexapi.wfghn.de/api/';
  @Output() userDataChanged: EventEmitter<Boolean> = new EventEmitter();

  constructor(private http: HttpClient) { }

  getUser(userId: string) {
    return this.http.get<User>(this.url + 'user/' + userId);
  }

  editUser(user: User) {
    localStorage.setItem( 'username', user.firstname + ' ' + user.lastname);
    return this.http.put(this.url + 'user', {user: user});
  }

  editAndUploadUser(files) {
    return this.http.post(this.url + 'user/upload', files);
  }

  setNewProfileImage(newImage) {
    localStorage.setItem( 'user_avatar', newImage);
    this.userDataChanged.emit();
  }

  setNewPw(newPW: string, user: User) {
    return this.http.put(this.url + 'user/password', {newPW: newPW, user: user});

  }
}
