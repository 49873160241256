import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  public url = 'https://matexapi.wfghn.de/api/';

  constructor(private http: HttpClient) {
  }

  getSectors() {
    return this.http.get(this.url + 'categories');
  }


}
