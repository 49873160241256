import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CompaniesService} from '../services/companies.service';
import {Company} from '../company';
import {ToastrService} from 'ngx-toastr';
import {AppGlobals} from '../../misc/globals';
import {User} from '../../user';
import {Location} from '@angular/common';
import {faUserEdit} from '@fortawesome/free-solid-svg-icons';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-company-print',
  templateUrl: './company-print.component.html',
  styleUrls: ['./company-print.component.scss']
})
export class CompanyPrintComponent implements OnInit, OnDestroy {
  alias: string;
  company: Company = new Company();
  user: User = new User;
  companyImages: any = [];
  servicesImages: any = [];
  referencesImages: any = [];

  public print = false;

  // ICONS
  public faEdit = faUserEdit;
  public faEye = faEye;
  public editIcon = this.faEye;

  constructor(private route: ActivatedRoute,
              private companiesService: CompaniesService,
              private router: Router,
              private toastr: ToastrService,
              public _global: AppGlobals,
              private _location: Location,
              private titleService: Title ) {
  }

  ngOnInit() {


      this.route.params.subscribe(params => {
      this.alias = params['alias'];
      this.user.name = localStorage.getItem('username');
      this.user.company = localStorage.getItem('user_company');
      this.user.email = localStorage.getItem('user_email');

        this.route.queryParams.subscribe(queryParams => {

          this.loadData();

          if (queryParams.type) {
            if (queryParams.type === 'print') {
              this.print = true;

              const afterPrint = () => {
                this.router.navigate(['/unternehmen/' + this.alias]);
              };


              window.onafterprint = afterPrint;
            }
          }
        });
    });
  }


  private loadData() {
    this.companiesService.getCompany(this.alias).subscribe((company: Company) => {
      this.company = company;
      this.titleService.setTitle('Matchmaking Tool - ' + this.company.title);

      if (this.company === null) {
        this.router.navigate(['/unternehmen']);
        this.toastr.error('Unternehmen konnte nicht gefunden werden.', 'Fehler');
      }

      this.generateCompanyImages();
      this.generateServicesImages();
      this.generateReferencesImages();


      setTimeout( () => {
        if (this.print) {
          window.print();
        }
      }, 1000);


    }, err => {
      this.router.navigate(['/unternehmen']);
      this.toastr.error('Unternehmen konnte nicht gefunden werden.', 'Fehler');
    });
  }


  private generateCompanyImages() {
    this.companyImages = [
      {
        path: this.company.companyImg1,
        name: 'companyImg1',
        caption: this.company.companyImg1Caption,

      },
      {
        path: this.company.companyImg2,
        name: 'companyImg2',
        caption: this.company.companyImg2Caption,

      },
      {
        path: this.company.companyImg3,
        name: 'companyImg3',
        caption: this.company.companyImg3Caption,

      },
      {
        path: this.company.companyImg4,
        name: 'companyImg4',
        caption: this.company.companyImg4Caption,

      },
    ];
  }

  private generateServicesImages() {
    this.servicesImages = [
      {
        path: this.company.servicesImg1,
        name: 'servicesImg1',
        caption: this.company.servicesImg1Caption

      },
      {
        path: this.company.servicesImg2,
        name: 'servicesImg2',
        caption: this.company.servicesImg2Caption
      },
      {
        path: this.company.servicesImg3,
        name: 'servicesImg3',
        caption: this.company.servicesImg3Caption
      },
      {
        path: this.company.servicesImg4,
        name: 'servicesImg4',
        caption: this.company.servicesImg4Caption
      },
    ];
  }

  private generateReferencesImages() {
    this.referencesImages = [
      {
        path: this.company.referencesImg1,
        name: 'referencesImg1',
        caption: this.company.referencesImg1Caption
      },
      {
        path: this.company.referencesImg2,
        name: 'referencesImg2',
        caption: this.company.referencesImg2Caption
      },
      {
        path: this.company.referencesImg3,
        name: 'referencesImg3',
        caption: this.company.referencesImg3Caption
      },
      {
        path: this.company.referencesImg4,
        name: 'referencesImg4',
        caption: this.company.referencesImg4Caption
      },
    ];
  }

  ngOnDestroy() {
    this.titleService.setTitle('Matchmaking Tool');
  }
}
