import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from "rxjs/operators";
import {log} from "util";

@Injectable({
  providedIn: 'root'
})
export class RouterServiceService {
  private history = [];

  constructor(private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/unternehmen';
  }
  public getLastCompanies(): string {
    const companies = this.history.filter(history => {
      if (history.includes('unternehmen/')) {
        return false;
      }
      return history.includes('unternehmen');
    });
    return companies[companies.length - 1] || '/unternehmen';
  }
}
