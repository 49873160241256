export class User {
  name: string;
  username: string;
  alias: string;
  firstname: string;
  lastname: string;
  company: string;
  position: string;
  avatar: string;
  email: string;
  street: string;
  postal: number;
  city: string;
  phone: string;
  password: string;
  constructor () {
  }
}
