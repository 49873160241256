import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../user';
import {AuthService} from '../../auth/auth.service';
import {log} from 'util';
import {ToastrService} from 'ngx-toastr';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public login = false;
  public user: User = new User;
  public loading: Boolean = false;


  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private toastr: ToastrService) {


    this.activatedRoute.queryParams.subscribe(params => {
      this.login = params['login'];
    });
  }



  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/unternehmen']);
    }
  }

  onSubmit () {
    this.loading = true;
    this.authService.login(this.user.email, this.user.password)
      .subscribe(
        () => {
          this.loading = false;
          this.router.navigate(['/unternehmen']);
          this.toastr.success('Sie haben sich erfolgreich eingeloggt.', 'Login erfolgreich');

        },
        (error) => {
          this.user = new User;
          this.loading = false;
          this.toastr.error('Bitte geben Sie Ihre Zugangsdaten erneut ein.', 'Login fehlgeschlagen');
        }
      );
  }
}
