import {Component, Input, OnInit, OnChanges, SimpleChange, SimpleChanges} from '@angular/core';
import {log} from 'util';
import {CompaniesService} from '../../companies/services/companies.service';
import {AppGlobals} from '../globals';
import {Lightbox, LIGHTBOX_EVENT, LightboxEvent} from 'ngx-lightbox';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-web-image',
  templateUrl: './web-image.component.html',
  styleUrls: ['./web-image.component.scss']
})
export class WebImageComponent implements OnInit {
  @Input() images: any;
  @Input() istEditable: Boolean = false;

  private alias: string;
  private _subscription: Subscription;

  public _albums: Array<any> = [];
  public enableUpload: Boolean = false;
  public editAvailable: Boolean = true;
  public maxImages: Array<any> = ['', '', '', ''];
  public defaultImage: String = './assets/images/account/default.png';
  public newFile: File[] = [];
  public sendableFormData: FormData;

  public loading: Boolean = false;

  constructor(private companiesService: CompaniesService,
              private _global: AppGlobals,
              private _lightbox: Lightbox,
              private route: ActivatedRoute,
              private _lightboxEvent: LightboxEvent,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.alias = params['alias'];
    });
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.images && this.images.length > 0) {
      for (let i = 0, p = Promise.resolve(); i < this.images.length; i++) {
        log(i);
        // @ts-ignore
        p = p.then(_ => new Promise((resolve) => {
            if (this.images[i].path != null) {
              this.companiesService.getImage(this.alias, this.images[i].name).subscribe((result: any) => {
                this.images[i].filename = result.name;
                const src = this._global.wfgURL + result.path;
                const album = {
                  src: src,
                  caption: this.images[i].caption
                };
                this._albums.push(album);
                resolve();
              });
            } else {
                this._albums.push({
                  src: this.defaultImage,
                  caption: ''
                });
                resolve();
            }
          }
        ));
      }
    }
  }

  open(index: number): void {
    this._lightbox.open(this._albums, index);
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('noscroll');

    this._subscription = this._lightboxEvent.lightboxEvent$
      .subscribe(event => this._onReceivedEvent(event));

  }

  fileChanged(i) {
    this.editAvailable = false;
    this._albums[i].activeEdit = true;
    this.enableUpload = true;
  }

  cancelEdit(i) {
    this.editAvailable = true;
    this._albums[i].activeEdit = false;
    this.enableUpload = false;
    this.loading = false;

  }

  uploadImage(i) {
   const dbRowTitle = this.images[i].name;
    this.sendableFormData.append('dbRowTitle', dbRowTitle);
    this.sendableFormData.append('caption', this._albums[i].caption);
    this.loading = true;

    this.companiesService.uploadImage(this.sendableFormData, localStorage.getItem('company_alias')).subscribe((data: any) => {
      this._albums[i].src = this._global.wfgURL + data.path ;
      this.images[i].filename = data.filename ;
      this.toastr.success('Das Bild wurde erfolgreich bearbeitet.', 'Bearbeitung erfolgreich');
      this.sendableFormData = new FormData();
      this.newFile = [];
      this.cancelEdit(i);
    }, err => {
      this.cancelEdit(i);
      this.toastr.error('Die Bearbeitung ist leider fehlgeschlagen.', 'Bearbeitung fehlgeschlagen');

    });
  }

  deleteImage(i) {
    const dbRowTitle = this.images[i].name;
    this.loading = true;
    this.companiesService.deleteImage(dbRowTitle, localStorage.getItem('company_alias'), this.images[i].filename).subscribe((data) => {
      this._albums[i].src = this.defaultImage;
      this._albums[i].caption = '';
      this.sendableFormData = new FormData();
      this.newFile = [];
      this.cancelEdit(i);
      this.toastr.success('Das Bild wurde erfolgreich entfernt.', 'Löschvorgang erfolgreich');

    }, err => {
      log(err);
      this.loading = false;
      this.toastr.error('Das Bild könnte leider nicht gelöscht werden,', 'Löschvorgang fehlgeschlagen.');

    });
  }
  private _onReceivedEvent(event: any): void {
    // remember to unsubscribe the event when lightbox is closed
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('noscroll');
    }
  }

}
