import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {RouterModule, Routes, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import { CompaniesComponent } from './companies/companies.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './account/login/login.component';
import {FormsModule} from '@angular/forms';
import { UserHeaderbarComponent } from './account/user-headerbar/user-headerbar.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './auth-interceptor';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from './auth/auth-guard';
import { ProfileComponent } from './account/profile/profile.component';
import {BreadcrumbsModule} from 'ng2-breadcrumbs';
import {FocusRemover} from './misc/button-focus-remover';
import {AppGlobals} from './misc/globals';
import { CompanyDetailsComponent } from './companies/company-details/company-details.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { FilterPipe } from './misc/filter.pipe';
import { HighliterPipe } from './misc/highliter.pipe';
import { ClickOutsideModule } from 'ng-click-outside';
import { OrderModule } from 'ngx-order-pipe';
import { TruncateModule } from 'ng2-truncate';
import { WebImageComponent } from './misc/web-image/web-image.component';
import {LightboxModule} from 'ngx-lightbox';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ngfModule} from 'angular-file';
import {NgxLoadingModule} from 'ngx-loading';
import {NgxWigModule} from 'ngx-wig';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CompanyPrintComponent } from './companies/company-print/company-print.component';
import {RouterServiceService} from './services/router-service.service';




const appRoutes: Routes = [
  { path: 'unternehmen',
    component: CompaniesComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Unternehmensübersicht'
    }
  },
  { path: 'unternehmen/:alias',
    component: CompanyDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Unternehmedetails'
    }
  },
  { path: 'unternehmen/print/:alias',
    component: CompanyPrintComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Print'
    }
  },
  { path: 'account/profil',
    component: ProfileComponent,
    canActivate: [AuthGuard]

  },
  { path: 'login', component: LoginComponent },

  { path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    CompaniesComponent,
    PageNotFoundComponent,
    LoginComponent,
    UserHeaderbarComponent,
    ProfileComponent,
    FocusRemover,
    CompanyDetailsComponent,
    FilterPipe,
    HighliterPipe,
    FocusRemover,
    WebImageComponent,
    CompanyPrintComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgxLoadingModule.forRoot({}),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false} // <-- debugging purposes only
    ),
    ToastrModule.forRoot(
      {
        timeOut: 4000,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true
      }
    ),
    BreadcrumbsModule,
    FilterPipeModule,
    ClickOutsideModule,
    OrderModule,
    TruncateModule,
    LightboxModule,
    NgxSmartModalModule.forRoot(),
    ngfModule,
    NgxWigModule,
    DeviceDetectorModule.forRoot()

  ],
  providers: [
    RouterServiceService,
    AuthGuard,
    AppGlobals,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
