import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProfileService} from './profile.service';
import {User} from '../../user';
import {log} from 'util';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../auth/auth.service';
import {NgxSmartModalComponent, NgxSmartModalService} from 'ngx-smart-modal';
import {AppGlobals} from '../../misc/globals';
import {
  faBriefcase,
  faEnvelope,
  faImage,
  faSave,
  faPhone,
  faUserEdit,
  faWindowClose, faChevronRight, faEdit, faKey
} from '@fortawesome/free-solid-svg-icons';
import {faMapMarker} from '@fortawesome/free-solid-svg-icons/faMapMarker';
import {CompaniesService} from '../../companies/services/companies.service';
import {Company} from '../../companies/company';
import {RouterServiceService} from "../../services/router-service.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public user: User = new User;
  public userId: string = localStorage.getItem('user_id');
  public companyAlias: string = localStorage.getItem('company_alias');
  public company: Company = new Company();
  public expiration: any;
  public editAreaAvailable = false;
  public userFile: File[] = [];
  public toastIsVisible = true;
  public previousPage: String;

  public faBriefcase = faBriefcase;
  public faMap = faMapMarker;
  public faEnvelope = faEnvelope;
  public faPhone = faPhone;
  public faClose = faWindowClose;
  public faEdit = faUserEdit;
  public faImage = faImage;
  public faSave = faSave;
  public faRight = faChevronRight;
  public faPw = faKey;
  public faCompanyEdit = faEdit;

  public sendableFormData: FormData;

  public loading: Boolean = false;
  public newPassword: string;
  public newPasswordSubmit: string;
  public defaultImage: String = './assets/images/account/default.png';

  constructor(private profileService: ProfileService,
              private toastr: ToastrService,
              private authService: AuthService,
              private companyService: CompaniesService,
              private ngxSmartModalService: NgxSmartModalService,
              public _global: AppGlobals,
              private routerService: RouterServiceService,
              private router: Router,
  ) {
    this.expiration = authService.getExpiration();
    this.previousPage = this.routerService.getLastCompanies();

  }

  ngOnInit() {
    this.getUserData();
  }

   historyBack() {
    // @ts-ignore
    this.router.navigateByUrl(this.previousPage);
  }

  editUser() {

    if (this.userFile.length > 0) {
      this.uploadFile();
      return false;
    }

    this.profileService.editUser(this.user).subscribe((response) => {
      this.toastr.success('Ihre Daten wurden erfolgreich geändert.', '');
      this.editAreaAvailable = false;

    }, err => {
      this.toastr.error('Leider ist ein Fehler aufgetreten', '');

    });
  }

  changePassword() {
    this.profileService.setNewPw(this.newPassword, this.user).subscribe((res) => {
      this.ngxSmartModalService.getModal('changePW').close();
      this.toastr.success('Ihr Passwort wurden erfolgreich geändert.', 'Passwort geändert');
    }, err => {
      log(err);
      this.toastr.error('Leider ist ein Fehler aufgetreten', 'Vorgang fehlgeschlagen');
    });
  }

  uploadFile() {
    this.loading = true;
    this.sendableFormData.append('userId', localStorage.getItem('user_id'));

    this.profileService.editAndUploadUser(this.sendableFormData).subscribe((newImage) => {
      this.profileService.editUser(this.user).subscribe((response) => {
        this.toastr.success('Ihre Daten wurden erfolgreich geändert.', '');
        this.editAreaAvailable = false;
        this.reloadImage(newImage);

        this.loading = false;


      }, err => {
        this.toastr.error('Leider ist ein Fehler aufgetreten', '');

      });

    }, err => {
      this.toastr.error('Leider ist ein Fehler aufgetreten', '');

    });
  }

  reset() {
    this.resetImage();
    this.getUserData();
  }

  resetImage() {
    this.userFile = [];
    this.sendableFormData = new FormData();
  }

  resetPw() {
    this.newPasswordSubmit = undefined;
    this.newPassword = undefined;
  }

  reloadImage(newImage) {
    this.profileService.setNewProfileImage(newImage);
  }

  private getUserData() {
    this.profileService.getUser(this.userId).subscribe((user: User) => {
      this.editAreaAvailable = false;
      this.user = user;
      this.user.avatar = localStorage.getItem('user_avatar');
      this.user.company = localStorage.getItem('user_company');

    }, err => {
      this.user = new User();
    });

    if (this.companyAlias !== null) {
      this.companyService.getCompany(this.companyAlias).subscribe((company: Company) => {
        this.company = company;
      });
    }
  }


}
