import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router, RoutesRecognized} from '@angular/router';
import {CompaniesService} from '../services/companies.service';
import {Company} from '../company';
import {ToastrService} from 'ngx-toastr';
import {AppGlobals} from '../../misc/globals';
import {User} from '../../user';
import {Location} from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {faUserEdit} from '@fortawesome/free-solid-svg-icons';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {DeviceDetectorService} from 'ngx-device-detector';
import {filter} from 'rxjs/operators';
import {RouterServiceService} from '../../services/router-service.service';
import {log} from "util";

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  activeTab: String = 'Unternehmen';
  truncateVal: any = 400;
  alias: string;
  company: Company = new Company();
  companyBackup: Company = new Company();
  user: User = new User;
  isOwner: Boolean = false;
  companyImages: any = [];
  servicesImages: any = [];
  referencesImages: any = [];
  contactForm: any = {
    subject: '',
    message: ''
  };

  public previousPage: String;
  public logoFile: File[] = [];
  public editText: String = 'bearbeiten';
  public activeEditTarget: String = '';
  public sendableFormData: FormData;
  public enableUpload: Boolean = false;
  public isMobileDevice = false;
  public toastIsVisible = true;
  public loading: Boolean = false;

  // ICONS
  public faEdit = faUserEdit;
  public faEye = faEye;
  public editIcon = this.faEye;

  constructor(private route: ActivatedRoute,
              private companiesService: CompaniesService,
              private router: Router,
              private toastr: ToastrService,
              public _global: AppGlobals,
              private _location: Location,
              public ngxSmartModalService: NgxSmartModalService,
              private deviceService: DeviceDetectorService,
              private routerService: RouterServiceService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.alias = params['alias'];
      this.user.name = localStorage.getItem('username');
      this.user.company = localStorage.getItem('user_company');
      this.user.email = localStorage.getItem('user_email');
      this.previousPage = this.routerService.getLastCompanies();

      this.loadData();
    });

  }

  public toggleView() {
    this.isOwner = !this.isOwner;

    if (this.isOwner) {
      this.editIcon = this.faEye;
    } else {
      this.editIcon = this.faEdit;
    }
  }


  public historyBack() {
    // @ts-ignore
    this.router.navigateByUrl(this.previousPage);
  }

  public activeEdit(e, target) {
    if (this.activeEditTarget !== target && this.activeEditTarget !== '') {
      this.ngxSmartModalService.getModal('openEditArea').open();
      return false;
    }
    this.activeEditTarget = target;

    if (this.editText === 'bearbeiten') {
      e.target.parentNode.classList.add('activeEdit');
      this.editText = 'Bearbeitung abbrechen';

    } else {
      e.target.parentNode.classList.remove('activeEdit');
      this.editText = 'bearbeiten';
      this.activeEditTarget = '';
      this.reset();
    }
  }


  public saveEdit(e, target) {
    this.activeEditTarget = '';
    e.target.parentNode.classList.remove('activeEdit');
    this.editText = 'bearbeiten';
    this.companiesService.editCompany(this.company, target).subscribe((result) => {
      this.toastr.success('Der Inhalt wurde erfolgreich bearbeitet.', 'Bearbeitung erfolgreich');
    }, err => {
      this.toastr.error('Der Inhalt konnte nicht bearbeitet werden.', 'Bearbeitung fehlgeschlagen.');
  });
  }

  public uploadFile() {
    const dbRowTitle = 'logo';
    this.loading = true;
    this.sendableFormData.append('dbRowTitle', dbRowTitle);
    this.sendableFormData.append('logo', 'true');

    this.companiesService.uploadImage(this.sendableFormData, localStorage.getItem('company_alias')).subscribe((data: any) => {
      this.enableUpload = false;
      this.loading = false;
      this.toastr.success('Das Unternehmeslogo wurde erfolgreich bearbeitet.', 'Bearbeitung erfolgreich');
      this.company.logopath = data.path;


    }, err => {
      this.loading = false;
      this.toastr.error('Das Bild konnte nicht geändert werden.', 'Vorgang fehlgeschlagen.');

    });
  }

  public setTab(activeTab) {
    this.activeTab = activeTab;
  }

  public sendForm() {
    const message = {
      from: {
        email: this.user.email,
        name: this.user.name,
        company: this.user.company
      },
      to: {
        email: this.company.username,
        name: this.company.firstname + ' ' + this.company.lastname,
        company: this.company.title
      },
      subject: this.contactForm.subject,
      message: this.contactForm.message
    };

    this.companiesService.postMessage(message).subscribe(() => {
      this.contactForm = {};
      this.toastr.success('Vielen Dank! Ihre Nachricht wurde erfolgreich versendet.', 'Nachricht versandt');
    }, err => {
      this.toastr.error('Ihre Nachricht konnte nicht versendet werden.', 'Nachricht senden fehlgeschlagen');

    });

  }

  public generateVcard() {
    const content = {
      firstname: this.company.firstname,
      lastname: this.company.lastname,
      email: this.company.username,
      phone: this.company.phone,
      company: this.company.title,
      position: this.company.position,
      address: this.company.address,
      postalCode: this.company.postal,
      city: this.company.city,
      website: this.company.website,
      avatar: this._global.wfgURL + '' + this.company.avatar

    };

    this.companiesService.postVcard(content).subscribe((url: string) => {
      this.toastr.success('Die VCard wurde erfolgreich generiert.', 'Erfolg');
      window.open(url, '_blank');
    }, error => {
      this.toastr.error('Das Generieren der VCard ist leider fehlgeschlagen. Versuchen Sie es bitte erneut.', 'Fehler');

    });
  }

  private loadData() {
    this.companiesService.getCompany(this.alias).subscribe((company: Company) => {
      this.company = company;
      this.companyBackup = company;

      if (this.company === null) {
        this.router.navigate(['/unternehmen']);
        this.toastr.error('Unternehmen konnte nicht gefunden werden.', 'Fehler');
      }

      if (this.user.company === this.company.title) {
        this.isOwner = true;

        this.isMobileDevice = this.deviceService.isMobile();

        if (this.isMobileDevice) {
          this.isOwner = false;
        }
      }

      if (this.companyImages.length === 0 && this.servicesImages.length === 0 && this.referencesImages.length === 0 ) {
        this.generateCompanyImages();
        this.generateServicesImages();
        this.generateReferencesImages();
      }


    }, err => {
      this.router.navigate(['/unternehmen']);
      this.toastr.error('Unternehmen konnte nicht gefunden werden.', 'Fehler');
    });
  }

  public reset() {
   this.loadData();
  }

  private generateCompanyImages() {
    this.companyImages = [
      {
        path: this.company.companyImg1,
        name: 'companyImg1',
        caption: this.company.companyImg1Caption,

      },
      {
        path: this.company.companyImg2,
        name: 'companyImg2',
        caption: this.company.companyImg2Caption,

      },
      {
        path: this.company.companyImg3,
        name: 'companyImg3',
        caption: this.company.companyImg3Caption,

      },
      {
        path: this.company.companyImg4,
        name: 'companyImg4',
        caption: this.company.companyImg4Caption,

      },
    ];
  }

  private generateServicesImages() {
    this.servicesImages = [
      {
        path: this.company.servicesImg1,
        name: 'servicesImg1',
        caption: this.company.servicesImg1Caption

      },
      {
        path: this.company.servicesImg2,
        name: 'servicesImg2',
        caption: this.company.servicesImg2Caption
      },
      {
        path: this.company.servicesImg3,
        name: 'servicesImg3',
        caption: this.company.servicesImg3Caption
      },
      {
        path: this.company.servicesImg4,
        name: 'servicesImg4',
        caption: this.company.servicesImg4Caption
      },
    ];
  }

  private generateReferencesImages() {
    this.referencesImages = [
      {
        path: this.company.referencesImg1,
        name: 'referencesImg1',
        caption: this.company.referencesImg1Caption
      },
      {
        path: this.company.referencesImg2,
        name: 'referencesImg2',
        caption: this.company.referencesImg2Caption
      },
      {
        path: this.company.referencesImg3,
        name: 'referencesImg3',
        caption: this.company.referencesImg3Caption
      },
      {
        path: this.company.referencesImg4,
        name: 'referencesImg4',
        caption: this.company.referencesImg4Caption
      },
    ];
  }

}


