import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const idToken = localStorage.getItem('id_token');

    if (idToken) {


      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + idToken
      });
      const cloneReq = req.clone({headers});

      return next.handle(cloneReq);


    } else {
      return next.handle(req);
    }
  }
}
