import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], args: string): any {
  if (args === undefined) {
    return items;
  }
    const ans = [];

    for (let i = 0; i < items.length; i++) {

      if (
        items[i].title.toLowerCase().match('^.*' + args.toLowerCase() + '.*$') ||
        items[i].tags.toLowerCase().match('^.*' + args.toLowerCase() + '.*$') ||
        items[i].postal.toLowerCase().match('^.*' + args.toLowerCase() + '.*$') ||
        items[i].sectorString.toLowerCase().match('^.*' + args.toLowerCase() + '.*$') ||
        items[i].city.toLowerCase().match('^.*' + args.toLowerCase() + '.*$')) {
        ans.push(items[i]);
      }
    }
    return ans;
  }

}
