import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {User} from '../../user';
import {AuthService} from '../../auth/auth.service';
import {NavigationEnd, Router} from '@angular/router';
import {log} from 'util';
import {ToastrService} from 'ngx-toastr';
import {e} from '@angular/core/src/render3';
import {AppGlobals} from '../../misc/globals';
import {ProfileService} from '../profile/profile.service';
import {faBars, faUserEdit} from "@fortawesome/free-solid-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {Location} from '@angular/common';

@Component({
  selector: 'app-user-headerbar',
  templateUrl: './user-headerbar.component.html',
  styleUrls: ['./user-headerbar.component.scss']
})
export class UserHeaderbarComponent implements OnInit {

  user: User = new User;
  loggedIn: Boolean = false;
  showHeader: Boolean = true;
  @Output() headerAvailable = new EventEmitter();


  public faBars = faBars;
  public faTimes = faTimes;


  currentAlias: string;
  public defaultImage: String = './assets/images/account/default.png';

  constructor(private router: Router,
              private authService: AuthService,
              private profileService: ProfileService,
              private toastr: ToastrService,
              private _global: AppGlobals,
              private _location: Location) {

    this.loggedIn = authService.isLoggedIn();

    authService.loggedInEvent.subscribe(state => {
      this.loggedIn = state;
      this.user.name = localStorage.getItem('username');
      this.user.company = localStorage.getItem('user_company');
      this.user.alias = localStorage.getItem('company_alias');
      this.user.avatar = localStorage.getItem('user_avatar');
    });

    profileService.userDataChanged.subscribe(() => {
      this.user.avatar = localStorage.getItem('user_avatar');
    });

    router.events.subscribe(event => {

      if (event instanceof NavigationEnd ) {
        this.currentAlias = event.url.split('/').slice(-1)[0];

      }
    });

  }

  ngOnInit() {

    this.user.name = localStorage.getItem('username');
    this.user.company = localStorage.getItem('user_company');
    this.user.alias = localStorage.getItem('company_alias');
    this.user.avatar = localStorage.getItem('user_avatar');


  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
    this.toastr.success('Sie haben sich erfolgreich ausgeloggt.');
  }

  toggleHeader() {
    this.showHeader = !this.showHeader;
    this.headerAvailable.emit(this.showHeader);

  }

  public historyBack() {
    this._location.back();
  }
}
