export const options = [
  {
    title: 'Namen aufsteigend',
    db: 'title',
    reverse: false,
    id: 0
  },
  {
    title: 'Namen absteigend',
    db: 'title',
    reverse: true,
    id: 1
  },
  {
    title: 'PLZ aufsteigend',
    db: 'postal',
    reverse: false,
    id: 2

  },
  {
    title: 'PLZ absteigend',
    db: 'postal',
    reverse: true,
    id: 3
  },
  {
    title: 'Ort aufsteigend',
    db: 'city',
    reverse: false,
    id: 4

  },
  {
    title: 'Ort absteigend',
    db: 'city',
    reverse: true,
    id: 5
  }

];
