import { Component } from '@angular/core';
import {RouterServiceService} from './services/router-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  headerIsAvailable: Boolean = true;

  constructor(private routerService: RouterServiceService) {

  }

  changerHeaderState = function (state) {
    this.headerIsAvailable = state;
  };

  onActivate(event) {
    window.scroll(0, 0);
  }

}
