import { Pipe, PipeTransform } from '@angular/core';
import {log} from 'util';

@Pipe({
  name: 'highliter'
})
export class HighliterPipe implements PipeTransform {

  transform(text: string, search): string {
    if (search !== undefined) {
      let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      pattern = pattern.split(' ').filter((t) => {
        return t.length > 0;
      }).join('|');
      const regex = new RegExp(pattern, 'gi');

      return search ? text.replace(regex, (match) => `<span class="highlight">${match}</span>`) : text;
    } else {
      return text;
    }

  }

}
