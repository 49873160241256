import {EventEmitter, Injectable, Output} from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/shareReplay';
import {log} from 'util';
import {catchError} from 'rxjs/internal/operators';
import {User} from '../user';



@Injectable({
  providedIn: 'root'
})


export class AuthService {
  public url = 'https://matexapi.wfghn.de/api/';

  @Output() loggedInEvent: EventEmitter<Boolean> = new EventEmitter();

  constructor(private http: HttpClient) {
    this.loggedInEvent.emit(false);
  }


  login(email: string, password: string ) {
    return this.http.post<User>(this.url + 'auth/user', {email, password})
      .do((data) => {
      this.setSession(data);
      this.loggedInEvent.emit(true);
      })
      .shareReplay();
  }

  private setSession(authResult) {
    const expiresAt = moment().add(authResult.expiresIn, 'second');
    localStorage.setItem( 'id_token', authResult.idToken);
    localStorage.setItem( 'expires_at', JSON.stringify(expiresAt.valueOf()) );
    localStorage.setItem( 'user_id', authResult.user.id);
    localStorage.setItem( 'username', authResult.user.firstname + ' ' + authResult.user.lastname);
    localStorage.setItem( 'user_company', authResult.user.companytitle);
    localStorage.setItem( 'company_alias', authResult.user.alias);
    localStorage.setItem( 'user_email', authResult.user.username);
    localStorage.setItem( 'user_avatar', authResult.user.avatar);

  }

  logout() {
    localStorage.removeItem( 'id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('user_company');
    localStorage.removeItem('company_alias');
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_avatar');
    this.loggedInEvent.emit(false);

  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }



}
