import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private toastr: ToastrService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (this.authService.isLoggedIn()) {
        // logged in so return true
        return true;
      } else {
        this.authService.logout();
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        this.toastr.error('Sie haben keine Berechtigung für diese Seite. Bitte loggen Sie sich ein.', 'Fehler');
        return false;
      }


    }
}
